import { render, staticRenderFns } from "./SupportButton.vue?vue&type=template&id=b8e84040&scoped=true"
import script from "./SupportButton.vue?vue&type=script&lang=js"
export * from "./SupportButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8e84040",
  null
  
)

export default component.exports