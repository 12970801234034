import { set, toggle } from "@/utils/vuex";

export default {
  setDrawer: set("drawer"),
  setColor: set("color"),
  toggleDrawer: toggle("drawer"),
  setLoader: set("loader"),
  setNotifier: set("notification"),
  setTraderMode: set("traderMode"),
  setShowChat: set("showChat"),
  setVersion: set("version"),
  setPendingRequests: set("pendingRequests"),
  setAppState: set("appState"),
  setIsLoggedIn: set("isLoggedIn"),
  setViewTemplate(state, payload) {
    localStorage.setItem("view_template", payload);
    state.view_template = payload;
  },
  setTariffsView(state, payload) {
    localStorage.setItem("tariffs_view", payload);
    state.tariffs_view = payload;
  },
  setProductTypeDescription(state, payload) {
    localStorage.setItem("product_type_description", payload);
    state.product_type_description = payload;
  },
  setShowOnlineShop: set("showOnlineShop"),
  setOnlineShopDescription: set("onlineShopDescription"),
  setMobilePrimaryMenuOpen: set("mobilePrimaryMenuOpen"),
  setInfoOpen: set("infoOpen"),
  setRegisterModalOpen: set("registerModalOpen"),
  setShowRegisterModalAfterRecurrent: set("showRegisterModalAfterRecurrent"),
  setIsActiveLinkInMenu: set("isActiveLinkInMenu"),
  setUniLevelStatuses: set("uniLevelStatuses"),
  setPaymentStep: set("paymentStep"),
  setGlobalSearch: set("globalSearch"),
  setScProducts: set("scProducts"),
  setShowSuspended: set("showSuspended"),
  setScrollableBody: set("scrollableBody"),
};
