// https://vuex.vuejs.org/en/getters.html

import { getLogoUrl } from "@/utils/getLogo";

export default {
  getConfig: (state) => state.config || {},
  getCurrency: (state) => state?.config?.currency || "",
  getCurrencySymbol: (_, __, ___, rootGetters) =>
    rootGetters["user/getMainWallet"]?.currencySymbol || "",
  getTraderMode: (state) => state.traderMode,
  getAppState: (state) => state.appState,
  getWhiteLabelUuid: (state) => state.config && state.config.uuid,
  getIsLoggedIn: (state) => state.isLoggedIn,
  getShowChat: (state) => state.showChat,
  showRefLink: (state, _, __, rootGetters) =>
    state.config?.show_ref_link_only_after_purchase
      ? !!rootGetters?.["user/getUser"]?.purchase
      : true,
  getViewTemplate: (state) => state.view_template,
  getShowOnlineShop: (state) => state.showOnlineShop,
  getOnlineShopDescription: (state) => state.onlineShopDescription,
  getAnonymousId: (state) => state.anonymousId,
  getRegisterModalOpen: (state) => state.registerModalOpen,
  isDepositDisabled: (state) => state.config.disable_deposit,
  getExpandedBalance: (state) => state.config.expanded_display_balance,
  getShowRegisterModalAfterRecurrent: (state) =>
    state.showRegisterModalAfterRecurrent,
  getEnabledModules: (state) => {
    return state.config && state.config.modules.map((m) => m.module);
  },
  getIsActiveLinkInMenu: (state) => state.isActiveLinkInMenu,
  isUniLevelPlan: (state) => {
    const affModule = state?.config?.aff_plan?.module;
    return affModule === "UnilevelTariffs" || affModule === "Unilevel";
  },
  isMsbotPlan: (state) => {
    const affModule = state?.config?.aff_plan?.module;
    return affModule === "Msbot";
  },
  isUniLevelTariffs: (state) => {
    const affModule = state?.config?.aff_plan?.module;
    return affModule === "UnilevelTariffs";
  },
  getUniLevelStatuses: (state, { isUniLevelPlan }) =>
    isUniLevelPlan && state.uniLevelStatuses,
  getMaxAllowedDepth: (state) =>
    state.config && state.config.aff_plan && state.config.aff_plan.max_depth,
  getPaymentStep: (state) => state.paymentStep,
  getLogoUrl: (_, { getConfig }) => getLogoUrl(getConfig),
  getGlobalSearch: (state) => state.globalSearch,
  getRefUrl: (_, { getConfig }, __, rootGetters) => {
    const refLink = getConfig?.ref_link;
    const user = rootGetters["user/getUser"];
    if (!user) {
      return null;
    }
    if (!refLink) {
      return location.origin + "/register/" + user.refid;
    }
    return refLink.replace("{LINK}", user.refid);
  },
  getScProducts: (state) => state.scProducts,
  getShowSuspended: (state) => state.showSuspended,
  isDarkTheme: (state) => state?.config?.design?.default_theme === "dark",
  getWlError: (state) => state?.wlError,
};
