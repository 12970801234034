var render = function render(){
  var _vm$getFilteredCMS;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-menu"
  }, [_c('v-layout', {
    staticClass: "menu primary-menu",
    attrs: {
      "column": ""
    }
  }, _vm._l(_vm.links, function (link, i) {
    return _c('v-list-item', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.showItem(link.key),
        expression: "showItem(link.key)"
      }],
      key: i,
      staticClass: "v-list-item",
      attrs: {
        "ripple": false,
        "to": link.to
      }
    }, [_c('v-list-item-action', [_c('icon-custom', {
      attrs: {
        "name": link.icon,
        "width": "20",
        "height": "20"
      }
    })], 1), _c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(link.text)
      }
    })], 1);
  }), 1), _vm.isCopyTrade ? _c('v-layout', {
    staticClass: "menu",
    attrs: {
      "column": ""
    }
  }, _vm._l(_vm.copyTradeLinks, function (link) {
    return _c('v-list-item', {
      key: link.key,
      staticClass: "v-list-item",
      attrs: {
        "ripple": false,
        "to": link.to
      }
    }, [_c('v-list-item-action', [_c('icon-custom', {
      attrs: {
        "name": link.icon,
        "width": "20",
        "height": "20"
      }
    })], 1), _c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(link.text)
      }
    })], 1);
  }), 1) : _vm._e(), _vm.getFilteredCMS && (_vm$getFilteredCMS = _vm.getFilteredCMS) !== null && _vm$getFilteredCMS !== void 0 && _vm$getFilteredCMS.length ? _c('v-layout', {
    staticClass: "menu",
    attrs: {
      "column": ""
    }
  }, _vm._l(_vm.getFilteredCMS, function (cms, i) {
    return _c('v-list-item', {
      key: i,
      staticClass: "v-list-item",
      attrs: {
        "to": `/template/${cms.id}`
      }
    }, [_c('v-list-item-action', [_c('span', {
      class: `mdi ${cms.icon}`,
      staticStyle: {
        "font-size": "20px"
      }
    })]), _c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(cms.name)
      }
    })], 1);
  }), 1) : _vm._e(), _vm.isLoggedIn ? _c('v-layout', {
    staticClass: "menu",
    attrs: {
      "column": ""
    }
  }, [_vm._l(_vm.preferenceLinks, function (link) {
    return _c('v-list-item', {
      key: link.key,
      staticClass: "v-list-item",
      attrs: {
        "ripple": false,
        "to": link.to
      }
    }, [_c('v-list-item-action', [_c('icon-custom', {
      attrs: {
        "name": link.icon,
        "width": "20",
        "height": "20"
      }
    })], 1), _c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(link.text)
      }
    })], 1);
  }), !_vm.getUser.auto_register ? _c('v-list-item', {
    staticClass: "v-list-item",
    attrs: {
      "ripple": false
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: _vm.loginPath,
          query: {
            s: 'logout'
          }
        });
      }
    }
  }, [_c('v-list-item-action', [_c('icon-custom', {
    attrs: {
      "name": "icn24_on_button",
      "width": "20",
      "height": "20"
    }
  })], 1), _c('v-list-item-title', {
    domProps: {
      "textContent": _vm._s(_vm.$t('toolbar.logOut'))
    }
  })], 1) : _vm._e()], 2) : _vm._e(), _vm.getUser && _vm.$vuetify.breakpoint.xs ? _c('div', {
    staticClass: "purchase ml-9"
  }, [_c('purchase')], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }