/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
  {
    path: "/withdrawals",
    name: "Withdrawals",
    view: "Withdrawals/Withdrawals",
    props: true,
    meta: { layout: "view" },
  },
  {
    path: "/balance",
    name: "Balance",
    view: "Balance/Balance",
    meta: { layout: "view" },
  },
  {
    path: "/briefcase",
    name: "Briefcase",
    view: "Briefcase/Briefcase",
    meta: { layout: "view" },
  },
  {
    path: "/projects",
    name: "Projects",
    view: "Projects/Projects",
    meta: { layout: "view", withoutAuth: true },
  },
  {
    path: "/tariffs",
    name: "Tariffs",
    view: "Tariffs/TariffsWrapper",
    meta: { layout: "view" },
  },
  {
    path: "/crowdfunding/:id",
    name: "Crowdfunding",
    view: "Crowdfunding/CrowdfundingPage",
    meta: { layout: "view" },
  },
  {
    path: "/roadmap",
    name: "Roadmap",
    view: "Roadmap/Roadmap",
    meta: { layout: "view" },
  },
  {
    path: "/my-lotteries",
    name: "MyLotteries",
    view: "Lottery/MyLotteriesList",
    meta: { layout: "view" },
  },
  {
    path: "/lotteries",
    name: "Lotteries",
    view: "Lottery/List",
    meta: { layout: "view" },
  },
  {
    path: "/lotteries/:id",
    name: "Lottery",
    view: "Lottery/LotteryPage",
    meta: { layout: "view" },
  },
  {
    path: "/products",
    name: "Products",
    view: "Products/Products",
    meta: { layout: "view" },
  },
  {
    path: "/store",
    name: "OnlineShop",
    view: "OnlineShop/OnlineShop",
    meta: { layout: "view-unregistered", withoutAuth: true },
  },
  {
    path: "/store/product/:id",
    name: "OnlineShopProduct",
    view: "OnlineShop/OnlineShopProductPage",
    meta: { layout: "view-unregistered", withoutAuth: true },
  },
  {
    path: "/product/:id",
    name: "Product",
    view: "Products/ProductPage",
    meta: { layout: "view" },
  },
  {
    path: "/my-courses",
    name: "Courses",
    view: "Courses/Courses",
    meta: { layout: "view" },
  },
  {
    path: "/course/:courseId/:lessonId",
    name: "Lesson",
    view: "Courses/Lesson",
    meta: { layout: "view-course" },
  },
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    view: "Dashboard/Dashboard",
    meta: { layout: "view" },
  },
  {
    path: "/investors",
    name: "Investors",
    view: "Investors/List",
    meta: { layout: "view" },
  },
  {
    path: "/invoices",
    name: "Invoices",
    view: "Invoices/List",
    meta: { layout: "view" },
  },
  {
    path: "/services",
    name: "Services",
    view: "Services/List",
    meta: { layout: "view" },
  },
  {
    path: "/invoice/:id",
    name: "View Invoice",
    view: "Invoices/View",
    meta: { layout: "view" },
  },
  {
    path: "/affiliates",
    name: "Affiliates",
    view: "Affiliates/Wrapper",
    meta: { layout: "view" },
  },
  {
    path: "/licenses",
    name: "Licenses",
    view: "Licenses/List",
    meta: { layout: "view" },
  },
  {
    path: "/settings",
    name: "Settings",
    view: "Settings/Settings",
    meta: { layout: "view" },
  },
  {
    path: "/security",
    name: "Security",
    view: "Security/View",
    meta: { layout: "view" },
  },
  {
    path: "/template/:id",
    name: "template",
    view: "Dynamic/Dynamic",
    meta: { layout: "view" },
  },
  {
    path: "/template/:id/:itemId",
    name: "template",
    view: "Dynamic/Article",
    meta: { layout: "view" },
  },
  // {
  //   path: "/wallets",
  //   name: "View Wallets",
  //   view: "Wallets/View",
  //   meta: { layout: "view" },
  // },
  // {
  //   path: "/wallets/verify/:id?",
  //   name: "AIV Wallet",
  //   view: "Wallets/Verify",
  //   meta: { layout: "view" },
  // },
  {
    path: "/calculator",
    name: "ROI Estimator",
    view: "Calculator/View",
    meta: { layout: "view" },
  },
  {
    path: "/merchants/buy/:symbol",
    name: "Buy AIV",
    view: "Merchants/View",
    meta: { layout: "view" },
  },
  {
    path: "/subscriptions",
    name: "Subscriptions",
    view: "Subscriptions/View",
    meta: { layout: "view" },
  },
  {
    path: "/gateway/pay/:id",
    name: "Gateway Pay",
    view: "Gateway/Process",
    meta: { layout: "view" },
  },
  {
    path: "/thankyou/:id?",
    name: "ThankYou",
    view: "Invoices/ThankYou",
    meta: { layout: "view" },
  },

  // Auth Routes

  {
    path: "/register/:id?",
    name: "Register",
    view: "Auth/Register",
    meta: { layout: "view-auth" },
  },
  {
    path: "/verifyemail/:id",
    name: "Verify Email",
    view: "Auth/VerifyEmail",
    meta: { layout: "view-auth" },
  },
  {
    path: "/login",
    name: "Login",
    view: "Auth/Login",
    meta: { layout: "view-auth", withoutAuth: true },
  },
  {
    path: "/autologin",
    name: "AutoLogin",
    view: "Auth/AutoLogin",
    meta: { layout: "view-auth", withoutAuth: true },
  },
  {
    path: "/oauthlogin",
    name: "OauthLogin",
    view: "Auth/OauthLogin",
    meta: { layout: "view-auth", withoutAuth: true },
  },
  {
    path: "/logout",
    name: "Logout",
    view: "Auth/Logout",
    meta: { layout: "view-auth" },
  },
  {
    path: "/resetpassword",
    name: "ResetPassword",
    view: "Auth/ResetPassword",
    meta: { layout: "view-auth" },
  },
  {
    path: "/newpassword/:id",
    name: "NewPassword",
    view: "Auth/NewPassword",
    meta: { layout: "view-auth" },
  },

  // Widget Routes
  {
    path: "/copy-trading/:id?",
    name: "CopyTrading",
    view: "CopyTrading/CopyTrading",
    meta: { layout: "view" },
  },
  {
    path: "/connection/:id",
    name: "Investor Details",
    view: "CopyTrading/Connections/Connections",
    meta: { layout: "view" },
  },
  {
    path: "/rankings/:id?",
    name: "Strategies",
    view: "CopyTrading/Strategies/Strategies",
    meta: { layout: "view", withoutAuth: true },
  },
  {
    path: "/ranking/:id",
    name: "Strategy",
    view: "CopyTrading/Strategies/Strategy",
    meta: { layout: "view", withoutAuth: true },
  },
  {
    path: "/my-accounts",
    name: "MyAccounts",
    view: "CopyTrading/MyAccounts/MyAccounts",
    meta: { layout: "view" },
  },
  {
    path: "/my-account/:id",
    name: "MyAccount",
    view: "CopyTrading/MyAccounts/MyAccount",
    meta: { layout: "view" },
  },
  {
    path: "/deals",
    name: "Deals",
    view: "Deals/Deals",
    meta: { layout: "view" },
  },
  {
    path: "/investments",
    name: "Investments",
    view: "Investments/Investments",
    meta: { layout: "view" },
  },
  {
    path: "/404",
    name: "404",
    view: "Error/Error",
    meta: { layout: "simple-layout" },
  },
  {
    path: "/408",
    name: "408",
    view: "Error/Error",
    meta: { layout: "simple-layout", withoutAuth: true },
  },
];
