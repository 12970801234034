/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from "vue";
import Router from "vue-router";

// Routes
import paths from "./paths";

function route(args) {
  const { view, name } = args;
  return {
    ...args,
    name: name || view,
    component: (resolve) => {
      import(`@/views/${typeof view === "string" ? view : view()}.vue`).then(
        (mode) => resolve(mode.default)
      );
    },
  };
}

Vue.use(Router);

// Create a new router
const router = new Router({
  mode: "history",
  routes: paths
    .map((path) => route(path))
    .concat([{ path: "*", redirect: "/404" }]),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.name == "AutoLogin") {
    next();
    return;
  }
  if (to.meta?.withoutAuth) {
    next();
    return;
  }
  if (to.meta.layout === "view-auth") {
    if (localStorage.getItem("auth-token") !== null && to.name !== "Logout") {
      next("/dashboard");
    } else {
      next();
    }
  } else if (localStorage.getItem("auth-token") !== null) {
    next();
  } else {
    next("/login");
  }
});

export default router;
