<template>
  <v-main>
    <core-warnings />
    <CoreDrawer v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm" />
    <div id="core-view" class="core-view">
      <div class="content">
        <header-mobile v-if="$vuetify.breakpoint.width < mobileBrakePoint" />
        <router-view :key="$route.path" />
      </div>
      <RightSidebar
        v-if="isLoggedIn && $vuetify.breakpoint.width >= mobileBrakePoint"
        class="sidebar"
      />
    </div>
    <template v-if="$vuetify.breakpoint.width < mobileBrakePoint">
      <footer-mobile-nav />
      <primary-menu-mobile />
      <info-mobile v-if="isLoggedIn" />
    </template>
  </v-main>
</template>

<script>
import WL_SUBSCRIPTION_QUERY from "@/graphql/queries/WhiteLabelUserActiveSubscription.gql";
import { mapGetters, mapMutations } from "vuex";
import RightSidebar from "../Info/InfoSidebar.vue";
import FooterMobileNav from "@/components/core/FooterMobileNav.vue";
import HeaderMobile from "@/components/core/HeaderMobile.vue";
import PrimaryMenuMobile from "@/components/core/PrimaryMenuMobile.vue";
import InfoMobile from "@/components/core/Info/InfoMobile.vue";
import CoreDrawer from "@/components/core/Drawer.vue";
import CoreWarnings from "@/components/core/Warnings.vue";

export default {
  name: "LayoutView",
  components: {
    CoreWarnings,
    CoreDrawer,
    RightSidebar,
    FooterMobileNav,
    HeaderMobile,
    PrimaryMenuMobile,
    InfoMobile,
  },
  data: () => ({
    settings: null,
    mobileBrakePoint: 1024,
  }),
  computed: {
    ...mapGetters({
      isLoggedIn: "app/getIsLoggedIn",
    }),
    isTariffView() {
      return this.$route.name === "Tariffs";
    },
  },
  beforeDestroy() {
    this.$apollo.queries.whiteLabelUserActiveSubscription.stopPolling();
  },
  methods: {
    ...mapMutations("user", ["setSubscription"]),
  },
  apollo: {
    whiteLabelUserActiveSubscription: {
      query: WL_SUBSCRIPTION_QUERY,
      fetchPolicy: "network-only",
      pollInterval: 30 * 1000,
      result(data) {
        if (!data.loading) {
          this.setSubscription(this.whiteLabelUserActiveSubscription);
        }
      },
      skip() {
        return !localStorage.getItem("auth-token");
      },
    },
  },
};
</script>

<style lang="scss">
.core-view {
  display: flex;
  background: var(--main-background);
  align-items: flex-start;

  & > .content {
    flex: 1 1 auto;
    overflow-x: clip;
    min-height: 100vh;
    max-height: 100vh;
  }

  .sidebar {
    flex: 1 1 300px;
    width: 300px;
  }
}

@media (max-width: 1023px) {
  .core-view {
    flex-direction: column;

    .content,
    .sidebar {
      flex: 1 1 100%;
      width: 100%;
    }
    .content {
      min-height: unset !important;
      max-height: unset !important;
    }

    .sidebar {
      order: 0;
    }

    .content {
      order: 1;

      .head-name {
        display: none;
      }
    }
  }
}
</style>
