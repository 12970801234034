import { render, staticRenderFns } from "./Cropper.vue?vue&type=template&id=485e60fe&scoped=true"
import script from "./Cropper.vue?vue&type=script&lang=js"
export * from "./Cropper.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "485e60fe",
  null
  
)

export default component.exports